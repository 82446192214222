<!--
 * @Author: Link
 * @Date: 2021-08-21 13:46:12
 * @LastEditTime: 2021-09-02 11:47:10
-->
<template>
  <div :class="`statistics`" ref="statistics">
    <div class="title">
      <img src="@/asset/icon/overview/pie.png" alt="" srcset="" />
      数据概况
    </div>
    <div class="content">
      <div class="item" v-for="(item, index) in list" :key="item.key" :style="`width:${shrink ? '25%' : '16.6%'}`">
        <div class="icon" v-if="item.type == 3 && data[item.key]">
          <el-progress
            :show-text="false"
            type="circle"
            :percentage="data[item.key].value * 100"
            :width="70"
            :stroke-width="8"
            color="#39B8FF"
          ></el-progress>
          <div class="num">{{ (data[item.key].value * 100).toFixed(1) }}%</div>
        </div>
        <div v-else class="icon">
          <img v-if="index < 4" :src="require(`@/asset/icon/overview/ring${index % 2 == 0 ? 1 : 2}.png`)" alt="" />
          <img v-else :src="require(`@/asset/icon/overview/ring${index % 2 == 0 ? 2 : 1}.png`)" alt="" />
          <div class="num" v-if="data[item.key]">{{ data[item.key].value }}</div>
        </div>
        <div v-if="data[item.key]">
          <div class="label">{{ item.name }}</div>
          <div class="yesterday" v-if="data[item.key].yesterday != undefined">
            昨日 {{ item.type == 3 ? data[item.key].yesterday * 100 + '%' : data[item.key].yesterday }}
            <img
              class="icon"
              :src="require(`@/asset/icon/overview/trend_${data[item.key].trend ? 'up' : 'down'}.png`)"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['shrink', 'rowData'],
  data() {
    return {
      list: [
        // {
        //   name: '已经下架',
        //   key: 'publishOffCount'
        // },
        // {
        //   name: '已上架',
        //   key: 'publishOnCount'
        // },
        // {
        //   name: '库存紧张',
        //   key: 'lowStockCount'
        // },
        // {
        //   name: '全部商品',
        //   key: 'totalProductCount'
        // },
        // {
        //   name: '今日新增',
        //   key: 'todayCount'
        // },
        // {
        //   name: '昨日新增',
        //   key: 'yesterdayCount'
        // },
        // {
        //   name: '本月新增',
        //   key: 'thisMonthCount'
        // },
        // {
        //   name: '会员总数',
        //   key: 'totalCount'
        // }
        {
          name: '访问次数PV',
          key: 'pv',
          type: '1' //1-图标1,2-图标2,3-环形图表
        },
        {
          name: '访问次数UV',
          key: 'uv',
          type: '2'
        },
        {
          name: '新增用户数',
          key: 'newMemberCount',
          type: '1'
        },
        {
          name: '首次下单用户数',
          key: 'firstPayMemberCount',
          type: '2'
        },
        {
          name: '复购用户数',
          key: 'repurchaseMemberCount',
          type: '1'
        },
        {
          name: '在售商品数',
          key: 'onSaleProductCount',
          type: '2'
        },
        {
          name: '商品上新数',
          key: 'newSaleProductCount',
          type: '1'
        },
        {
          name: '交易转换率',
          key: 'dealConvertRate',
          type: '3'
        }
      ],
      data: {}
    }
  },
  watch: {
    rowData(v) {
      let temp = {}
      Object.keys(v).map(key => {
        if (!key.includes('yesterday')) {
          let key2 = 'yesterday' + key[0].toUpperCase() + key.slice(1)
          if (key === 'newMemberCount' || key === 'firstPayMemberCount') {
            key2 = 'yesterdayIs' + key[0].toUpperCase() + key.slice(1)
          }
          if (key === 'newSaleProductCount') {
            key2 = 'yesterdaySaleProductCount'
          }
          temp[key] = {
            value: v[key],
            yesterday: v[key2],
            trend: v[key] > v[key2]
          }
        }
      })
      this.data = temp
    }
  }
}
</script>

<style lang='less' scoped>
.statistics {
  background: #fff;
  padding: 15px;
  border-radius: 15px;
  box-shadow: 0px 4px 12px -4px rgba(179, 181, 186, 0.08);
  .title {
    display: flex;
    align-items: center;
    color: rgba(71, 72, 73, 1);
    font-family: HarmonyOS Sans SC;
    font-weight: bold;
    font-size: 17px;
    letter-spacing: 1px;
    margin-bottom: 15px;
    img {
      width: 30px;
      height: 30px;
      margin-right: 10px;
    }
  }
  .content {
    display: flex;
    flex-wrap: wrap;
    .item {
      min-width: 250px;
      width: 25%;
      display: flex;
      align-items: center;
      padding: 15px;
      user-select: none;
      transition: all 0.5s;
      display: flex;
      // &:hover {
      //   .icon {
      //     img {
      //       transform: rotate(360deg);
      //     }
      //   }
      // }
      .icon {
        width: 70px;
        height: 70px;
        position: relative;
        margin-right: 15px;
        img {
          transition: all 0.7s;
          width: 100%;
          height: 100%;
        }
        .num {
          position: absolute;
          width: 100%;
          text-align: center;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-size: 17px;
          font-weight: bold;
          font-family: HarmonyOS Sans SC;
          word-break: break-all;
        }
      }
      .label {
        color: rgba(71, 72, 73, 1);
        font-family: HarmonyOS Sans SC;
        font-size: 17px;
      }
      .yesterday {
        color: rgba(128, 129, 145, 1);
        margin-top: 4px;
        font-family: HarmonyOS Sans SC;
        font-size: 14px;
        .icon {
          width: 18px;
          height: auto;
        }
      }
    }
  }
}
</style>